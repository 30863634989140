

import View from '@/components/view';

const storeIndex = () => import(/* webpackChunkName: "org-audit" */ '@/pages/hxcm/store/index'); // 医疗机构审核
const storeDetail = () => import(/* webpackChunkName: "org-detail" */ '@/pages/hxcm/store/detail'); // 企业详情
const storeInfoAudit = () => import(/* webpackChunkName: "org-info-audit" */ '@/pages/hxcm/store/audit'); // 企业资料审核

const routes = [
    {
        path: 'store',
        component: View,
        meta: {
            title: '店铺审核'
        },
        children: [
            {
                path: 'index',
                component: storeIndex,
                meta: {
                    title: '店铺审核'
                }
            },
            {
                path: 'detail',
                component: storeDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'audit',
                component: storeInfoAudit,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
