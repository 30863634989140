/*
 * @Author: qurz
 * @Email: qurz@weiyi.com
 * @Date: 2023-10-123 15:00:00
 * @Last Modified by: qurz
 * @Last Modified time: 2023-10-123 15:41:00
 */

import View from '@/components/view';

const CertListIndex = () => import(/* webpackChunkName: "ca-cert" */ '@/pages/hxcm/ca-cert/index'); // CA订单列表
const CertDetail = () => import(/* webpackChunkName: "ca-cert" */ '@/pages/hxcm/ca-cert/detail'); // CA订单详情
const InvoiceList = () => import(/* webpackChunkName: "ca-cert" */ '@/pages/hxcm/ca-cert/invoiceList'); // CA开票列表
const CertManageList = () => import(/* webpackChunkName: "ca-cert" */ '@/pages/hxcm/ca-cert/certManageList'); // CA证书管理列表

const routes = [
    
    {
        path: 'ca-cert',
        component: View,
        meta: {
            title: 'CA证书平台'
        },
        children: [
            {
                path: 'index',
                component: CertListIndex,
                meta: {
                    title: '订单查询',
                    keepAlive: true
                }
            },
            {
                path: 'detail',
                component: CertDetail,
                meta: {
                    title: '订单详情',
                    display: 'none'
                }
            },
            {
                path: 'invoiceList',
                component: InvoiceList,
                meta: {
                    title: '订单开票',
                    keepAlive: true
                }
            },
            {
                path: 'certManageList',
                component: CertManageList,
                meta: {
                    title: '证书管理',
                    keepAlive: true
                }
            }
        ]
    }
];

export default routes;
