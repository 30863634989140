/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: xujh
 * @Last Modified time: 2020-05-08 15:42:09
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

// const varietiesList = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/varieties/index');
const vanList = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/varieties/vanList');
const routes = [
    {
        path: 'varieties',
        component: View,
        meta: {
            title: '品种管理'
        },
        children: [
            // {
            //     path: 'list',
            //     component: varietiesList,
            //     meta: {
            //         title: '品种管理'
            //     }
            // },
            {
                path: 'vanList',
                component: vanList,
                meta: {
                    title: '品种管理'
                }
            }
        ]
    }
];

export default routes;
