/*
 * @Author: linkg
 * @Email: linkg@hxmec.com
 * @Date: 2022-02-22 14:49:58
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-03-24 22:23:33
 * @Description: Description
 * @Route: Route
 */

import View from '@/components/view';

export default [
    {
        path: 'project-inner/sample-manage',
        component: View,
        meta: {
            title: '样品管理'
        },
        children: [
            {
                path: 'index',
                component: () => import(/* webpackChunkName: "sample-manage-index" */ '@/pages/hxcm/sample-manage/index'),
                meta: {
                    title: '样品管理'
                }
            }
        ]
    }
];
