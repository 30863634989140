/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2019-09-20 10:51:38
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-07-02 16:25:16
 * @Description:  install
 */
import Header from './vw-header';
import CardBox from './card-box';
import Rectangle from './rectangle';
import Li from './li';

const components = [
    Header,
    CardBox,
    Rectangle,
    Li
];

const install = Vue => {
    components.forEach(component => (
        Vue.component(component.name, component)
    ));
};

export default {
    install,
    Header
};
