/*
 * @Description: 中医馆报量专区-项目内
 */
import View from '@/components/view';

const routes = [
    {
        path: 'project-inner',
        component: View,
        meta: {
            title: '项目内'
        },
        children: [
            {
                path: 'purchase-statistics',
                component: () => import('@/pages/hxcm/cm-report/purchase-statistics/index'),
                meta: {
                    title: '采购需求量统计'
                }
            }
        ]
    }
];

export default routes;
