/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: xujh
 * @Last Modified time: 2020-05-08 15:42:09
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const noticeList = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/notice/noticeList');
const drafts = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/notice/drafts');
const noticeDetail = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/notice/notice-detail');
const fund = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/notice/fund-detail');
const routes = [
    {
        path: 'notice',
        component: View,
        meta: {
            title: '公告管理'
        },
        children: [
            {
                path: 'noticeList',
                component: noticeList,
                meta: {
                    title: '公告管理'
                }
            },
            {
                path: 'noticeList/detail',
                component: noticeDetail,
                meta: {
                    title: '发布公告',
                    display: 'none'
                }
            },
            {
                path: 'drafts',
                component: drafts,
                meta: {
                    title: '草稿箱'
                }
            },
            {
                path: 'noticeList/fund',
                component: fund,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
