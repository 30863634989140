/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-08-10 10:48:35
 * @Last Modified by: Stoner
 * @Last Modified time: 2022-04-01 11:01:09
 * @Description: 申报审核
 */

import View from '@/components/view';

// const orgAudit = () => import(/* webpackChunkName: "org-audit" */ '@/pages/hxcm/declare/org-audit'); // 企业资料审核
// const orgChangeLog = () => import(/* webpackChunkName: "org-change-log" */ '@/pages/hxcm/declare/org/change-log'); // 企业变更记录
// const orgContrast = () => import(/* webpackChunkName: "org-contrast" */ '@/pages/hxcm/declare/org/contrast'); // 企业比对

const orgAudit1 = () => import(/* webpackChunkName: "org-audit" */ '@/pages/hxcm/declare/org-audit1'); // 医疗机构审核
const orgAudit2 = () => import(/* webpackChunkName: "org-audit" */ '@/pages/hxcm/declare/org-audit2'); // 企业单位审核

const orgDetail1 = () => import(/* webpackChunkName: "org-detail" */ '@/pages/hxcm/declare/org/detail1'); // 企业详情
const orgInfoAudit1 = () => import(/* webpackChunkName: "org-info-audit" */ '@/pages/hxcm/declare/org/audit1'); // 企业资料审核
const orgDetail2 = () => import(/* webpackChunkName: "org-detail" */ '@/pages/hxcm/declare/org/detail2'); // 企业详情
const orgInfoAudit2 = () => import(/* webpackChunkName: "org-info-audit" */ '@/pages/hxcm/declare/org/audit2'); // 企业资料审核

const prodChangeLog = () => import(/* webpackChunkName: "prod-change-log" */ '@/pages/hxcm/declare/prod/change-log'); // 产品变更记录
const prodDetail = () => import(/* webpackChunkName: "prod-detail" */ '@/pages/hxcm/declare/prod/detail'); // 产品详情
const prodContrast = () => import(/* webpackChunkName: "prod-contrast" */ '@/pages/hxcm/declare/prod/contrast'); // 产品比对

// const prodAudit = () => import(/* webpackChunkName: "prod-audit" */ '@/pages/hxcm/declare/prod-audit'); // 中药材产品审核
// const prodInfoAudit = () => import(/* webpackChunkName: "prod-info-audit" */ '@/pages/hxcm/declare/prod/audit'); // 产品资料审核

const resetAudit = () => import(/* webpackChunkName: "reset-audit" */ '@/pages/hxcm/declare/reset-audit'); // 重置审核状态

const routes = [
    {
        path: 'declare',
        component: View,
        meta: {
            title: '申报审核'
        },
        children: [
            // {
            //     path: 'org-audit',
            //     component: orgAudit,
            //     meta: {
            //         title: '企业资料审核'
            //     }
            // },
            {
                path: 'org-audit1',
                component: orgAudit1,
                meta: {
                    title: '医疗机构'
                }
            },
            {
                path: 'org-audit2',
                component: orgAudit2,
                meta: {
                    title: '企业单位'
                }
            },

            {
                path: 'org-audit1/detail',
                component: orgDetail1,
                meta: {
                    title: '医疗机构详情',
                    display: 'none'
                }
            },
            {
                path: 'org-audit1/audit',
                component: orgInfoAudit1,
                meta: {
                    title: '医疗机构审核',
                    display: 'none'
                }
            },

            {
                path: 'org-audit2/detail',
                component: orgDetail2,
                meta: {
                    title: '企业单位详情',
                    display: 'none'
                }
            },
            {
                path: 'org-audit2/audit',
                component: orgInfoAudit2,
                meta: {
                    title: '企业单位审核',
                    display: 'none'
                }
            },

            // {
            //     path: 'org-audit/change-log',
            //     component: orgChangeLog,
            //     meta: {
            //         title: '变更记录',
            //         display: 'none'
            //     }
            // },

            // {
            //     path: 'org-audit/change-log/contrast',
            //     component: orgContrast,
            //     meta: {
            //         title: '资料比对',
            //         display: 'none'
            //     }
            // },

            {
                path: 'prod-audit/change-log',
                component: prodChangeLog,
                meta: {
                    title: '变更记录',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit/detail',
                component: prodDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'prod-audit/change-log/contrast',
                component: prodContrast,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'reset-audit',
                component: resetAudit,
                meta: {
                    title: '重置审核状态',
                    display: 'none'
                }
            }

        ]
    }
];

export default routes;
