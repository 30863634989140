/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-07-02 15:02:24
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const Config = () => import(/* webpackChunkName: "screen-config" */ '@/pages/hxcm/screen/config');
const Visual = () => import(/* webpackChunkName: "screen-config" */ '@/pages/hxcm/screen/visual');

const routes = [
    {
        path: 'screen',
        component: View,
        meta: {
            title: '监管'
        },
        children: [
            {
                path: 'visual',
                component: Visual,
                meta: {
                    title: '监管首页'
                }
            },
            {
                path: 'config',
                component: Config,
                meta: {
                    title: '大屏参数配置'
                }
            }
        ]
    }
];

export default routes;
