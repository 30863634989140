/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-08-10 10:48:35
 * @Last Modified by: tangliang
 * @Last Modified time: 2022-03-02 15:10:35
 * @Description: 项目管理
 */

import View from '@/components/view';

const routes = [
    
    {
        path: 'insurance',
        component: View,
        meta: {
            title: '保险管理'
        },
        children: [
            {
                path: 'insurance-order',
                component: () => import(/* webpackChunkName: "insurance-order" */ '@/pages/hxcm/insurance/insurance-order'),
                meta: {
                    title: '投保单管理'
                }
            }
        ]
    }
];

export default routes;
