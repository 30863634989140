/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-17 10:24:05
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-07-22 15:19:55
 * @Description: 中药饮片管理后台模块路由
 */

import View from '@/components/view';
import declare from './declare'; // 申报审核
import declare2 from './declare2'; // 申报共享审核
import productManage from './product-manage';// 产品管理
import order from './order';
import InforInq from './information-inquiry';
import varieties from './varieties';// 品种管理
import purchasingProject from './purchasing';// 集采项目管理
import financialManage from './financial-manage';// 集采项目管理
import Qualificate from './qualificate'; // 产品审核
import PutNetwork from './put-network'; // 直接挂网
import notice from './notice';
import screen from './screen';
import store from './store';
import traceAbility from './trace-ability';// 溯源
import sampleManage from './sample-manage'; 
import Project from './project';
// import InclusionReleaseManage from './inclusion-release-manage';
// import NegotiationsManagement from './negotiations-management';
import insurance from './insurance';
import caCert from './ca-cert';
import BdDictionary from './bd-dictionary';
import cmReport from './cm-report';
// 公告管理
export default [{
    path: '/hxcm',
    component: View,
    meta: {
        title: '中药饮片管理后台',
        noTag: true
    },
    children: [
        ...insurance,
        ...caCert,
        // ...NegotiationsManagement,
        // ...InclusionReleaseManage,
        ...Project,
        ...declare,
        ...declare2,
        ...productManage,
        ...order,
        ...varieties,
        ...notice,
        ...InforInq,
        ...purchasingProject,
        ...financialManage,
        ...screen,
        ...sampleManage,
        ...PutNetwork,
        ...Qualificate,
        ...store,
        ...traceAbility,
        ...BdDictionary,
        ...cmReport
    ]
}];
// 新原型图路由结构
// export default [
//     {
//         path: '/hxcm',
//         component: View,
//         meta: {
//             title: '中药饮片管理后台',
//             noTag: true
//         },

//     },
//     ...declare,
//     ...productManage,
//     ...order,
//     ...varieties,
//     ...notice,
//     ...InforInq
// ];
