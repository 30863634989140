<template>
    <div
        class="sd-rectangle"
        :style="{background:background}">
        <div
            v-for="(item,key) in items"
            :key="key"
            :class="`block ${classS[key]}`"
            :style="{flexDirection:(column? 'column':'row'),padding:padding}">
            <p
                class="color-size"
                :style="{marginBottom:marginB}">
                <i>{{ item.number }}</i>
                <small>{{ item.small }}</small>
                <em
                    v-show="item.arrow"
                    :class="item.arrow" />
            </p>
            <span class="rectangle_tip">{{ item.tips }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'VwRectangle',
    props: {
        // 内容的排序方式
        column: {
            type: Boolean,
            required: false,
            default: true
        },
        // 用于控制字体颜色，给三个值 block-blue,block-yellow,block-green 默认是block-yellow
        classS: {
            type: Array,
            required: false,
            default: () => []
        },
        // 数字相对提示的底边距
        marginB: {
            type: String,
            required: false,
            default: ''
        },
        // 内容的内边距
        padding: {
            type: String,
            required: false,
            default: ''
        },
        // 去除背景色
        background: {
            type: String,
            required: false,
            default: ''
        },
        // 内容部分number:数字，small：单位，tips文字标识
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {};
    },
    mounted() {}
};
</script>
<style lang='less'>
.sd-rectangle {
    background: rgba(44, 188, 173, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .block {
        color: #fff;
        font-size: 12px;
        flex: 1;
        display: flex;
        padding: 5px 0;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        span {
            margin: 2px 5px;
        }
        p.color-size {
            margin-bottom: 3px;
            margin: 2px 5px;
            color: @primary-yellow;
            i {
                font-style: normal;
                font-size: 24px;
                font-weight: 700;
                font-family: din;
            }
            small {
                font-weight: bold;
            }
            .up {
                background: url('~@/images/visual/up.png') no-repeat center / 100%;
                width: 16px;
                height: 14px;
                font-weight: 700;
                display: inline-block;
                margin-bottom: -3px;
            }
            .down {
                background: url('~@/images/visual/down.png') no-repeat center / 100%;
                width: 16px;
                height: 14px;
                font-weight: 700;
                display: inline-block;
                margin-bottom: -3px;
            }
        }

        .rectangle_tip {
            font-size: 12px;
        }
    }
    .block-blue {
        p.color-size {
            color: @primary-blue;
        }
    }
    .block-yellow {
        p.color-size {
            color: @primary-yellow;
        }
    }
    .block-green {
        p.color-size {
            color: @primary-green;
        }
    }
    .block:nth-of-type(1) {
        border-left: 0;
    }
}
</style>
