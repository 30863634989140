/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-03 12:56:28
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-07-26 18:59:03
 * @Description: 微前端方案
 */

import '@babel/polyfill';
import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Element from 'element-ui';
import singleSpaVue from 'single-spa-vue';
import VueSwiper from 'vue-awesome-swiper';
import storeData from '@/store';
import fetch from '@/utils/fetch';
import configs from '@/config';
import '@/styles/custom';
/* eslint-disable */
import Viewer from '@heisea/viewer';
import vViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import Components from '@/components/index';
import { getUrlParame } from '@/utils/utils';
import PdfPreview from '@/components/pdf-preview/index';

import AntdInit from './antd-init';
import 'ant-design-vue/dist/antd.css';
AntdInit();

Vue.use(Components);
Vue.use(VueSwiper);
Vue.use(PdfPreview);

Vue.use(Viewer);
Vue.use(vViewer);
vViewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
});

function init({ publicPath = 'hxcm', routes = {} }, callback) {
    Vue.config.productionTip = false;
    const env = process.env.NODE_ENV;
    Vue.config.silent = env !== 'development';
    let menuObj = {}; // 项目内菜单

    const router = new VueRouter({
        mode: 'history',
        // mode:'hash',
        routes
    });
    Vue.use(VueRouter);
    Vue.use(Element);
    if (callback)callback(Vue, router);
    const store = env === 'development' ? storeData : window.Store;
    Vue.mixin({
        methods: {
            $fetch(options) {
                // 避免应用互相污染
                return this.$root.fetch(options);
            }
        }
    });
    Vue.prototype.$bkConfig = configs;
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="app" class="global-wrapper">
                <router-view></router-view>
        </div>,
        watch: {
            $route() {
                // 微前端 项目内外处理
                this.initMenu();
            }
        },
        methods: {
            fetch: config => fetch(config),
            initMenu() {
                // 微前端 项目内外处理
                if (window.Store) {
                    const projectId = getUrlParame('itemId');
                    const menu = menuObj[projectId];
                    if (menu) {
                        window.Store.commit('GET_APPLY_MENU', menu);
                        return;
                    }
                    const isCmReport = getUrlParame('gCode') === 'cmReport';
                    if (projectId) {
                        window.App.$root.fetch({
                            url: isCmReport ?
                             '/tcme/api/project/menu/inner': // 中医馆报量
                             '/hxupsc/api/project/menu/inner', // 联采
                            params: {
                                projectId,
                                systemId: localStorage.getItem('system_id')
                            }
                        }).then(res => {
                            if (res) {
                                menuObj[projectId] = res.data;
                                window.Store.commit('GET_APPLY_MENU', res.data);
                            }
                        });
                    } else {
                        const { outer } = menuObj;
                        if (outer) {
                            window.Store.commit('GET_APPLY_MENU', outer);
                            return;
                        }
                        let options = {
                            url:'/upms/menu'
                        };
                        // 联采
                        if(location.pathname.indexOf('purchasing') !== -1){
                            options = {
                                url: '/hxupsc/api/project/menu/outer',
                                params: {
                                    systemId: localStorage.getItem('system_id')
                                }
                            }
                        }
                        // 中医馆报量
                        if(isCmReport){
                            options = {
                                url: '/tcme/api/project/menu/outer',
                                params: {
                                    systemId: localStorage.getItem('system_id')
                                }
                            }
                        }
                        window.App.$root.fetch(options).then(res => {
                            if (res) {
                                menuObj.outer = res.data;
                                window.Store.commit('GET_APPLY_MENU', res.data);
                            }
                        });
                    }
                }
            }
        },
        store,
        router,
        mounted() {
            menuObj = {}
            this.initMenu()
        }
    };

    // 区分项目内，项目外菜单
    router.beforeEach((to, from, next) => {
        const { path: toPath, query } = to;
        const { itemId, gCode } = query;
        const { itemId: fromItemId, projectId, id, projectFullName, gCode: fGcode } = from.query;
        const token = localStorage.getItem('token');
        localStorage.setItem('rootProductId', itemId);
        const loginPath = ['/login', '/portal', '/aup'];

        const inner = 'project-inner';

        // 回到登录
        if (!token && to.path !== loginPath) {
            next({
                path: loginPath,
                query: {
                    path: to.fullPath
                }
            });
        }
        const outerPath = '/hxcm/cm-report/project-manage'; // 中医馆报量项目外路由
        if (outerPath === toPath && !gCode && fGcode) {
            next({
                path: toPath,
                query: {
                    gCode: fGcode,
                    ...to.query
                }
            });
            return;
        }
        // 执行跳转
        if (toPath.indexOf(inner) !== -1 && !itemId && fromItemId) {
            next({
                path: toPath,
                query: {
                    itemId: fromItemId,
                    projectId,
                    id,
                    projectFullName,
                    gCode: fGcode,
                    ...to.query
                }
            });
        } if (toPath !== outerPath && !itemId &&fromItemId) {
            next({
                path: toPath,
                query: {
                    itemId: fromItemId,
                    gCode: fGcode,
                    ...to.query
                }
            });
        } else {
            next();
        }
    });

    let vueLifecycles = {}; // 微前端

    if (env === 'development') {
        window.App = new Vue(appOptions).$mount('#app'); // 手动挂载
    } else {
        setPublicPath(publicPath); // 注册应用
        store.commit('SAVE_ROUTE_DATA', routes); // 保存路由信息到全局
        if(location.pathname.indexOf('cm-report') !== -1 || getUrlParame('gCode') === 'cmReport'){
            window.Store.commit('SET_BACK_OUTER', {
                path: '/hxcm/cm-report/project-manage'
            });
        } else if(sessionStorage.getItem('noBtn')!==null){
            window.Store.commit('SET_BACK_OUTER', {
                path: '/hxupsc/manage-org/proj-out/purchasing/list?noBtn='+sessionStorage.getItem('noBtn')
            });
        }else{
            window.Store.commit('SET_BACK_OUTER', {
                path: '/hxupsc/manage-org/proj-out/purchasing/list'
            });
        }
       
        vueLifecycles = singleSpaVue({
            Vue,
            appOptions
        });
    }


    return vueLifecycles;
}

// 项目启动的钩子
export default init;
