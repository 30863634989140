/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: tangliang
 * @Last Modified time: 2020-09-04 16:03:31
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const BankWater = () => import(/* webpackChunkName: "bank-water" */ '@/pages/hxcm/information-inquiry/bank-water');
const routes = [
    {
        path: 'information-inquiry',
        component: View,
        meta: {
            title: '信息查询'
        },
        children: [
            {
                path: 'bank-water',
                component: BankWater,
                meta: {
                    title: '银行流水查询'
                }
            },
            {
                path: 'order-inquiry',
                component: () => import(/* webpackChunkName: "order-inquiry" */ '@/pages/hxcm/information-inquiry/order-inquiry'),
                meta: {
                    title: '订单收/支查询'
                }
            },
            {
                path: 'ams-manage/pay-water',
                component: () => import(/* webpackChunkName: "ams-manage" */ '@/pages/hxcm/information-inquiry/ams-manage/pay-water'),
                meta: {
                    title: '支付流水查询'
                }
            },
            {
                path: 'ams-manage/pay-water-detail',
                component: () => import(/* webpackChunkName: "ams-manage" */ '@/pages/hxcm/information-inquiry/ams-manage/pay-water-detail'),
                meta: {
                    title: '支付流水详情',
                    display: 'none'
                }
            },
            {
                path: 'ams-manage/paybank-config',
                component: () => import(/* webpackChunkName: "ams-manage" */ '@/pages/hxcm/information-inquiry/ams-manage/paybank-config'),
                meta: {
                    title: '支付渠道配置'
                }
            },
            {
                path: 'ams-manage/agency-account',
                component: () => import(/* webpackChunkName: "ams-manage" */ '@/pages/hxcm/information-inquiry/ams-manage/agency-account'),
                meta: {
                    title: '机构账户管理'
                }
            }
        ]
    }
];

export default routes;
