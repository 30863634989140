/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-06-28 09:31:11
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const index = () => import(/* webpackChunkName: "invoice-index" */ '@/pages/hxcm/financial-manage/invoice/index');
const detail = () => import(/* webpackChunkName: "invoice-detail" */ '@/pages/hxcm/financial-manage/invoice/detail');
const statementIndex = () => import(/* webpackChunkName: "invoice-index" */ '@/pages/hxcm/financial-manage/statement/index');
const statementDetail = () => import(/* webpackChunkName: "invoice-detail" */ '@/pages/hxcm/financial-manage/statement/detail');
const reconciliation = () => import(/* webpackChunkName: "invoice-detail" */ '@/pages/hxcm/financial-manage/reconciliation/index');
const payment = () => import(/* webpackChunkName: "invoice-detail" */ '@/pages/hxcm/financial-manage/payment/index');

const routes = [
    {
        path: 'financial-manage',
        component: View,
        meta: {
            title: '财务管理'
        },
        parentPath: '/hxcm/financial-manage',
        children: [
            {
                path: 'invoice',
                component: index,
                meta: {
                    title: '发票管理'
                }
            },
            {
                path: 'invoice/detail',
                component: detail,
                meta: {
                    title: '发票详情',
                    display: 'none'
                }
            },
            {
                path: 'statement',
                component: statementIndex,
                meta: {
                    title: '结算单'
                }
            },
            {
                path: 'statement/detail',
                component: statementDetail,
                meta: {
                    title: '结算单详情',
                    display: 'none'
                }
            },
            {
                path: 'reconciliation',
                component: reconciliation,
                meta: {
                    title: '对账单'
                }
            },
            {
                path: 'payment',
                component: payment,
                meta: {
                    title: '支付单'
                }
            }
        ]
    }
];

export default routes;
