/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-08-10 10:48:35
 * @Last Modified by: Stoner
 * @Last Modified time: 2022-03-24 16:38:18
 * @Description: 申报审核
 */

import View from '@/components/view';

const orgAudit = () => import(/* webpackChunkName: "org-audit" */ '@/pages/hxcm/declare2/org-audit'); // 医疗机构审核
const orgAuditDetail = () => import(/* webpackChunkName: "org-detail" */ '@/pages/hxcm/declare2/org-audit-detail'); // 企业详情
const orgAuditAudit = () => import(/* webpackChunkName: "org-info-audit" */ '@/pages/hxcm/declare2/org-audit-audit'); // 企业资料审核

const routes = [
    {
        path: 'declare2',
        component: View,
        meta: {
            title: '申报共享审核'
        },
        children: [
            {
                path: 'org-audit',
                component: orgAudit,
                meta: {
                    title: '企业单位'
                }
            },
            {
                path: 'org-audit/detail',
                component: orgAuditDetail,
                meta: {
                    title: '企业单位详情',
                    display: 'none'
                }
            },
            {
                path: 'org-audit/audit',
                component: orgAuditAudit,
                meta: {
                    title: '企业单位审核',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
