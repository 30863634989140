/*
 * @Author: zhuangmw
 * @Email: zhuangmw@guahao.com
 * @Date: 2022-01-10 13:53:02
 * @Last Modified by: Danielz
 * @Last Modified time: 2023-09-28 14:19:51
 * @Description: Description
 * @Route: Route
 */

import View from '@/components/view';

export default [
    {
        path: 'qualificate',
        component: View,
        meta: {
            title: '产品审核'
        },
        children: [
            {
                path: 'gmp',
                component: () => import(/* webpackChunkName: "gmp-index" */ '@/pages/hxcm/qualificate/gmp'),
                meta: {
                    title: '中药饮片'
                }
            },
            {
                path: 'gmp-declare-set',
                component: () => import(/* webpackChunkName: "gmp-declare-set" */ '@/pages/hxcm/qualificate/gmp/declare-set'),
                meta: {
                    title: '精选饮片申报设置'
                }
            },
            {
                path: 'gmp/edit',
                component: () => import(/* webpackChunkName: "gmp-edit" */ '@/pages/hxcm/qualificate/edit'),
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'gmp/review',
                component: () => import(/* webpackChunkName: "gmp-review" */ '@/pages/hxcm/qualificate/review'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'granule',
                component: () => import(/* webpackChunkName: "granule-index" */ '@/pages/hxcm/qualificate/granule'),
                meta: {
                    title: '配方颗粒'
                }
            },
            {
                path: 'granule/edit',
                component: () => import(/* webpackChunkName: "gmp-edit" */ '@/pages/hxcm/qualificate/edit'),
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'granule/review',
                component: () => import(/* webpackChunkName: "gmp-review" */ '@/pages/hxcm/qualificate/edit'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'medicinal-materials',
                component: () => import(/* webpackChunkName: "granule-index" */ '@/pages/hxcm/qualificate/medicinal-materials'),
                meta: {
                    title: '中药材'
                }
            },
            {
                path: 'medicinal-materials/edit',
                component: () => import(/* webpackChunkName: "gmp-edit" */ '@/pages/hxcm/qualificate/medicinal-materials/edit'),
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'medicinal-materials/review',
                component: () => import(/* webpackChunkName: "gmp-review" */ '@/pages/hxcm/qualificate/medicinal-materials/edit'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];
