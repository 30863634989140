/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: xujh
 * @Last Modified time: 2020-09-07 15:42:09
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const OrderList = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/order/index');
const OrderDetail = () => import(/* webpackChunkName: "order-detail" */ '@/pages/hxcm/order/order-detail'); // 订单详情
const routes = [
    {
        path: 'order',
        component: View,
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'list',
                component: OrderList,
                meta: {
                    title: '订单'
                }
            },
            {
                path: 'order-detail',
                component: OrderDetail,
                meta: {
                    title: '订单详情',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
