/*
 * @Author: danielz
 * @Email: zhangp1@hxmec.com
 * @Date: 2022-12-22 10:59:18
 * @Last Modified by: Danielz
 * @Last Modified time: 2022-12-26 10:02:46
 * @Description: Description
 * @Route: /hxcm/trace-ability
 */

import View from '@/components/view';

const TraceAbilityIndex = () => import('@/pages/hxcm/trace-ability/index');
const SendCodeIndex = () => import('@/pages/hxcm/trace-ability/send');
const ManageIndex = () => import('@/pages/hxcm/trace-ability/manage');
const TraceAbilityHot = () => import('@/pages/hxcm/trace-ability/hot');
const TraceAbilityInfo = () => import('@/pages/hxcm/trace-ability/info');
const routes = [
    {
        path: 'trace-ability',
        component: View,
        meta: {
            title: '溯源管理'
        },
        children: [
            {
                path: 'index',
                component: TraceAbilityIndex,
                meta: {
                    title: '溯源业务配置'
                }
            },
            {
                path: 'info',
                component: TraceAbilityInfo,
                meta: {
                    title: '溯源码详情',
                    display: 'none'
                }
            },
            {
                path: 'send',
                component: SendCodeIndex,
                meta: {
                    title: '溯源码发放'
                }
            },
            {
                path: 'manage',
                component: ManageIndex,
                meta: {
                    title: '溯源码管理'
                }
            },
            {
                path: 'hot',
                component: TraceAbilityHot,
                meta: {
                    title: '溯源数据看板'
                }
            }
           
        ]
    }
];

export default routes;
