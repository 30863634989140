export default {
    responseProps: {
        code: 'code', // 响应状态码
        data: 'data', // 响应数据
        message: 'msg', // 响应文案信息
        successCode: 0 // 成功码
    },
    responseListProps: {
        results: 'records', // 响应数组数据
        pageSize: 'pageSize', // 页长
        pageNumber: 'pageNo', // 页码
        totalCount: 'count', // 页面数据总量
        cursorCode: 'cursorCode' // 游标标记字段-没用
    },
    // 文件响应数据键值
    responseFileProps: {
        fileId: 'id', // 文件标识字段
        fileUrl: 'url', // 文件地址
        fileName: 'originName', // 文件名
        fileSuffix: 'ext' // 文件后缀
    },
    tablePaneDisplay: true, // 列表右上角-字段显示隐藏控制开关。
    // tableDownload: { // 列表右上角下载列表开关
    //     show: true,
    //     pageSize: 10000,
    //     eachSize: 500
    // },
    // 验证码
    vcodePath: '//apitcme.guahao-test.com/code',
    uploadPath: '//apitcme.guahao-test.com/code/base/fe/upload', // 上传
    filePath: 'https://apitcme.guahao-test.com/code/base/fe/info', // 文件信息
    batchFilePath: '//apitcme.guahao-test.com/code/base/fe/batchInfo', // 批量获取文件信息
    downloadPath: '//apitcme.guahao-test.com/code/base/fe/download'
};
