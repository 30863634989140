/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-07-12 17:16:36
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const index = () => import(/* webpackChunkName: "upsc-index" */ '@/pages/hxcm/purchasing/index');
const draft = () => import(/* webpackChunkName: "upsc-draft" */ '@/pages/hxcm/purchasing/draft');
const edit = () => import(/* webpackChunkName: "upsc-edit" */ '@/pages/hxcm/purchasing/edit');
const detail = () => import(/* webpackChunkName: "upsc-detail" */ '@/pages/hxcm/purchasing/detail');
const audit = () => import(/* webpackChunkName: "upsc-detail" */ '@/pages/hxcm/purchasing/supplier-audit');
const review = () => import(/* webpackChunkName: "upsc-detail" */ '@/pages/hxcm/purchasing/supplier-review');


const volume = () => import(/* webpackChunkName: "upsc-volume" */ '@/pages/hxcm/purchasing/volume');
const bargaining = () => import(/* webpackChunkName: "upsc-bargaining" */ '@/pages/hxcm/purchasing/bargaining');
const bid = () => import(/* webpackChunkName: "upsc-bid" */ '@/pages/hxcm/purchasing/bid');
const spread = () => import(/* webpackChunkName: "upsc-spread" */ '@/pages/hxcm/purchasing/spread');
const notice = () => import(/* webpackChunkName: "upsc-notice" */ '@/pages/hxcm/purchasing/notice');
const noticeAdd = () => import(/* webpackChunkName: "upsc-noticeAdd" */ '@/pages/hxcm/purchasing/noticeAdd');
const selected = () => import(/* webpackChunkName: "upsc-selected" */ '@/pages/hxcm/purchasing/selected');
const winner = () => import(/* webpackChunkName: "upsc-winner" */ '@/pages/hxcm/purchasing/winner');
const decrypt = () => import(/* webpackChunkName: "upsc-decrypt" */ '@/pages/hxcm/purchasing/decrypt');
const routes = [
    {
        path: 'purchasing',
        component: View,
        meta: {
            title: '集采项目'
        },
        parentPath: '/hxcm/purchasing',
        children: [
            {
                path: 'create',
                component: edit,
                meta: {
                    title: '创建项目'
                }
            },
            {
                path: 'edit',
                component: edit,
                meta: {
                    title: '编辑项目',
                    display: 'none'
                }
            },
            {
                path: 'project-inner/detail',
                component: detail,
                meta: {
                    title: '查看项目',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/decrypt',
                component: decrypt,
                meta: {
                    title: 'CA解密配置',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'draft',
                component: draft,
                meta: {
                    title: '草稿箱'
                }
            },
            {
                path: 'list',
                component: index,
                meta: {
                    title: '项目列表'
                }
            },
            {
                path: 'project-inner/volume',
                component: volume,
                meta: {
                    title: '报量审核',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/notice',
                component: notice,
                meta: {
                    title: '项目公告',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/supplier-audit',
                component: audit,
                meta: {
                    title: '供应商报名审核',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/supplier-review',
                component: review,
                meta: {
                    title: '供应商报价入选审核',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/noticeAdd',
                component: noticeAdd,
                meta: {
                    title: '发布公告',
                    display: 'none'
                }
            },
            {
                path: 'project-inner/selected',
                component: selected,
                meta: {
                    title: '入选公示',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/bargaining',
                component: bargaining,
                meta: {
                    title: '议价',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/winner',
                component: winner,
                meta: {
                    title: '中选公示',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/bid',
                component: bid,
                meta: {
                    title: '中标',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            },
            {
                path: 'project-inner/spread',
                component: spread,
                meta: {
                    title: '挂网',
                    display: 'none',
                    backUrl: '/hxupsc/manage-org/proj-out/purchasing/list',
                    inner: true
                }
            }
        ]
    }
];

export default routes;
