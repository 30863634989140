/*
 * @Author: zhuangmw
 * @Email: zhuangmw@guahao.com
 * @Date: 2022-02-09 16:25:54
 * @Last Modified by: zhuangmw
 * @Last Modified time: 2022-02-09 16:30:12
 * @Description: Description
 * @Route: Route
 */

import View from '@/components/view';

const PutNetwork = () => import(/* webpackChunkName: "put-network" */ '@/pages/hxcm/put-network/index');

const routes = [
    {
        path: 'put-net',
        component: View,
        meta: {
            title: '直接挂网'
        },
        children: [
            {
                path: 'index',
                component: PutNetwork,
                meta: {
                    title: '审核'
                }
            }
        ]
    }
];

export default routes;
