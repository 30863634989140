/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-08-18 14:09:21
 * @Last Modified by: Stoner
 * @Last Modified time: 2022-07-08 10:59:18
 * @Description: Description
 * @Route: /hxcm/product-manage
 */

import View from '@/components/view';

const ProductIndex = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/product-manage/index'); // 商品管理
const ProductDetail = () => import(/* webpackChunkName: "publish-detail" */ '@/pages/hxcm/product-manage/detail'); // 商品查看
const GmpIndex = () => import(/* webpackChunkName: "product-publish" */ '@/pages/hxcm/goods/gmp/index'); // 中药饮片
const GmpDetail = () => import(/* webpackChunkName: "publish-detail" */ '@/pages/hxcm/goods/gmp/detail'); // 中药饮片查看
const routes = [
    {
        path: 'product-manage',
        component: View,
        meta: {
            title: '商品管理'
        },
        children: [
            {
                path: 'index',
                component: ProductIndex,
                meta: {
                    title: '中药材',
                    keepAlive: true
                }
            },
            {
                path: 'detail',
                component: ProductDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'gmp/index',
                component: GmpIndex,
                meta: {
                    title: '中药饮片',
                    keepAlive: true
                }
            },
            {
                path: 'granule/index',
                component: GmpIndex,
                meta: {
                    title: '配方颗粒',
                    keepAlive: true
                }
            },
            {
                path: 'gmp/detail',
                component: GmpDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'granule/detail',
                component: GmpDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
