export default {
    baseUrl: '//api.bqex.com/code', // 运维中心数据服务
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 上传
    uploadPath: '//api.bqex.com/code/base/fe/upload', // 上传
    filePath: '//api.bqex.com/code/base/fe/info', // 文件信息
    batchFilePath: '//api.bqex.com/code/base/fe/batchInfo', // 批量获取文件信息
    downloadPath: '//api.bqex.com/code/base/fe/download',
    baseOss: {
        '//api.bqex.com/code': ''
    }
};
