/*
 * @Author: zhujial
 * @Email: zhujial@hxmec.com
 * @Date: 2023-05-22 17:30:25
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-07-22 15:13:09
 * @Description: 分量系统
 * @Route: 
 */
import View from '@/components/view';
import Inner from './inner'; // 管理单位

const projectList = () => import(/* webpackChunkName: "project-manage-list" */ '@/pages/hxcm/cm-report/project-manage/list'); // 项目列表

export default [
    {
        path: 'cm-report',
        component: View,
        meta: {
            title: '中医馆报量专区'
        },
        children: [
            {
                path: 'project-manage',
                component: projectList,
                meta: {
                    title: '项目列表'
                }
            },
            ...Inner
        ]
    }
];
