<template>
    <div v-if="currentVisible"
         class="dialog_box_mask">
        <div :class="type === 'pdf' ? 'PDF_dialog_box': 'slot_dialog_box'"
             :style="{width:size,height:size}">
            <div v-if="type === 'pdf'"
                 class="PDF_dialog_close"
                 @click="close">
                关闭窗口
            </div>
            <div v-else
                 class="slot_dialog_header">
                <span class="slot_dialog_title">{{ title }}</span><button type="button"
                                                                          class="el-dialog__headerbtn"
                                                                          @click="close">
                    <i
                        class="el-dialog__close el-icon el-icon-close" />
                </button>
            </div>
            <iframe id="framework"
                    title="framework"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    :src="pdfUrl" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => ({
                url: '',
                title: '',
                size: '100%',
                type: 'pdf'
            })
        },
        url: {
            type: String,
            default: '' // pdfUrl
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentVisible: this.visible,
            pdfUrl: this.url || this.info.url,
            title: '',
            size: '100%',
            type: 'pdf'
        };
    },
    watch: {
        visible(val, old) {
            if (val === old) return;
            this.currentVisible = val;
            // if (val) {

            // }
        }
    },
    created() {
    },
    methods: {
        handleVisible(val) {
            if (val) {
                this.currentVisible = true;
                if (typeof val === 'string') {
                    this.pdfUrl = val;
                } else {
                    this.pdfUrl = val.url;
                    this.title = val.title;
                    this.size = val.size;
                    this.type = val.type;
                }
                // this.currentVisible = true;
                // const { fileUrl, fileName } = this.props;
                // if (typeof val !== 'object') {
                //     // 补全
                //     this.pdfInfo = {
                //         [fileUrl]: val,
                //         [fileName]: val
                //     };
                // } else if (val[fileUrl]) {
                //     this.pdfInfo = val;
                // } else {
                //     this.currentVisible = false;
                //     console.error('缺少有效地址');
                // }
            }
        },
        close() {
            this.currentVisible = false;
            this.$emit('update:visible', false);
        }
    }
};
</script>

<style lang="less">
.dialog_box_mask {
    width:100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}
.PDF_dialog_box {
    width: 100%;
    height: 100%;
    background: #333;
    .PDF_dialog_close {
        font-size: 15px;
        text-align: right;
        padding: 0 30px;
        color: #ccc;
        cursor: pointer;
    }
}

.slot_dialog_box {
    height: 100%;
    background-color: #fff;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}
.slot_dialog_title{
    font-size: 16px;
    font-weight: 700;
    line-height: 47px;
    color:#303133;
}
.slot_dialog_title::before {
    content: '';
    width: 6px;
    height: 16px;
    margin-right: 4px;
    display: inline-block;
    vertical-align: middle;
    background-color: #1b65b9;
    margin-top: -3px;
}

.slot_dialog_header {
    position: relative;
    border-bottom: 1px solid #cecece;
    padding: 0 16px;
    min-height: 47px;
    background: #fff;
    overflow: hidden;
}

.slot_dialog_header {
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}
</style>>
