/*
 * @Author: zhujl
 * @Date: 2023-12-06 16:30:09
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-12-07 15:57:55
 */

import View from '@/components/view';

const ManuEnterpriseList = () => import(/* webpackChunkName: "manu-enterprise-list" */ '@/pages/hxcm/bd-dictionary/manu-enterprise-list'); // CA订单列表

const routes = [
    
    {
        path: 'bd-dictionary',
        component: View,
        meta: {
            title: '基础数据字典'
        },
        children: [
            {
                path: 'manu-enterprise-list',
                component: ManuEnterpriseList,
                meta: {
                    title: '生产企业名录'
                }
            }
        ]
    }
];

export default routes;
